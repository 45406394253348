import AITemplate from '../images/AIGenerate.png';
import SendBulk from '../images/SendBulk.png'
import BulkSend from '../images/BulkEmail.png'
import MailSent from '../images/MailSent.png'

export const featuresData = [
  {
    id: 1,
    title: 'Save Hours of Work',
    description:
      'With automated generation and sending, you’ll focus more on closing deals and less on repetitive tasks.',
    image: SendBulk,
    width: 300,
    height: 300,
    reverse: true,
  },
  {
    id: 2,
    title: 'Effortless AI Cold Email Generation',
    description:
      'Generate hundreds of personalized cold email templates in seconds. Save time and increase outreach efficiency with minimal effort.',
    image: AITemplate,
    width: "120px",
    height: "120px",
    reverse: false,
  },
  {
    id: 3,
    title: 'Personalized Outreach',
    description:
      'Customize each email with dynamic fields, ensuring every recipient feels like you’ve crafted a message just for them.',
    image: BulkSend,
    width: 44,
    height: 40,
    reverse: true,
  },
  {
    id: 4,
    title: 'Effortless Scheduling',
    description:
      'Automate email sending at your preferred time, maximizing the impact of your outreach campaigns.',
    image: MailSent,
    width: 256,
    height: 256,
    reverse: false,
  },
];