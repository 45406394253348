import axiosInstance from './axiosConfig';
import {getErrorMessage} from "../utils/errorHandler";

export const fetchAllEmailHistory = async () => {
  try {
    const response = await axiosInstance.get('/email/history');
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const fetchAllScheduledEmails = async () => {
  try {
    const response = await axiosInstance.get('/email/schedule');
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const cancelScheduledEmail = async (emailId) => {
  try {
    await axiosInstance.delete(`/email/schedule/cancel/${emailId}`);
    return {success: true};
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
