export const faqs = [
  {
    question: 'How does the cold email generation work?',
    answer: 'Our tool uses AI to generate customized email templates based on your input, allowing you to create emails quickly and efficiently.',
  },
  {
    question: 'Can I use my own email templates?',
    answer: 'Yes, you can upload your custom email templates, making it easier to align with your unique messaging and branding.',
  },
  {
    question: 'How many emails can I send at once?',
    answer: 'Our platform allows you to send a large volume of emails simultaneously, ensuring broad reach in minimal time.',
  },
  {
    question: 'Is my data secure while using this tool?',
    answer: 'Yes, we prioritize your security. We only store your templates and Google account details, and we do not keep any email sending logs.',
  },
];
