import React, {useState} from 'react';
import GoogleLogo from '../assets/images/google.png';
import NamedLogo from '../assets/images/NamedLogo.png';
import useAuthCheck from '../hooks/useAuth';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const Login = () => {
  useAuthCheck();

  // State to track checkbox status
  const [isChecked, setIsChecked] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Handle login click
  const handleLoginClick = (e) => {
    if (!isChecked) {
      e.preventDefault(); // Prevent redirection
      toast.error("Accept Terms and Conditions to login");
    }
  };

  return (
    <div className="mulish-normal min-h-screen flex-col flex items-center bg-white">
      <div className="flex gap-[40%] pt-4 px-4 w-full justify-start items-center">
        <Link to="/">
          <button
            className="text-xl self-start text-gray-700 hover:text-gray-900"
          >
            <ArrowLeftOutlined/>
          </button>
        </Link>
        <Link to="/">
          <img src={NamedLogo} alt="FrostMail Logo" className="cursor-pointer self-center h-10"/>
        </Link>
      </div>
      <div className="max-w-md my-auto flex flex-col w-full bg-white px-6 rounded-lg">

        {/* Heading */}
        <h2 className="text-center text-2xl -mt-20 font-bold mb-4">
          Welcome to FrostMail
        </h2>

        {/* Google Sign-In Button */}
        <a
          href="https://coldemail-mgln.onrender.com/oauth/authorization/google"
          type="button"
          onClick={handleLoginClick}
          className={`w-full bg-gray-50 flex items-center gap-4 justify-center text-gray-600 py-2 rounded-lg mt-2 hover:bg-gray-100 transition-colors ${
            !isChecked ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <img className="h-6" src={GoogleLogo} alt="Sign In with Google"/>
          <span>Login with Google</span>
        </a>

        {/* Custom Checkbox and Terms */}
        <div className="mt-4 flex items-center">
          <label className="flex items-center cursor-pointer space-x-2">
            <div className="relative">
              <input
                type="checkbox"
                id="termsCheckbox"
                className="hidden"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <div
                className={`w-6 h-6 border-2 mr-2 rounded-md transition-all ${isChecked ? 'bg-blue-600 border-blue-600' : 'border-gray-400'}`}></div>
              {isChecked && (
                <svg
                  className="absolute top-0 left-0 w-6 h-6 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              )}
            </div>
            <span className="text-sm text-gray-600">
              By continuing, you agree to our{' '}
              <a
                href="/terms-of-service"
                className="text-blue-500 hover:text-blue-700"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="/privacy-policy"
                className="text-blue-500 hover:text-blue-700"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>.
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Login;
