import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Modal, Select, Skeleton} from 'antd';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  ReloadOutlined,
  RobotOutlined
} from '@ant-design/icons';
import {deleteTemplate, fetchUserTemplates, generateTemplateWithAI, saveTemplate} from '../network/Template';
import {toast} from 'react-toastify';
import {encodeToken} from '../utils/tokenUtils';
import {setAuthTokenAction} from '../redux/auth/authActions'
import Cookies from "js-cookie";
import ProfileHeader from '../components/Layout/ProfileHeader';

const ChooseTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = useSelector((state) => state.auth.token);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [templateDetails, setTemplateDetails] = useState({
    templateType: '',
    templateFormat: '',
    templateData: '',
    templateId: ''
  });

  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [templateDescription, setTemplateDescription] = useState('');
  const [generatedContent, setGeneratedContent] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state for saving
  const [isFetching, setIsFetching] = useState(false); // Loading state for fetching templates
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false); // Modal for previewing HTML content


  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');

    // Check if token is in params or Redux, otherwise redirect to login
    if (!token && !authToken) {
      toast.error('Session expired, please log in again.');
      navigate('/login');
      return;
    }

    // If token is in params but not in Redux or cookies, set it
    if (token && !authToken) {
      // Encode the token
      const encodedToken = encodeToken(token);

      // Set the token as a cookie
      Cookies.set('cold-emails', encodedToken, {
        expires: 3, // Expire in 3 days
        secure: true, // Ensures cookie is sent over HTTPS
        sameSite: 'strict', // Prevents CSRF
      });

      dispatch(setAuthTokenAction(token));
    }

    const loadTemplates = async () => {
      setIsFetching(true);
      try {
        // Make API call to fetch user templates
        const userTemplates = await fetchUserTemplates();
        setTemplates(userTemplates);
      } catch (error) {
        toast.error('Failed to fetch templates.');
      } finally {
        setIsFetching(false);
      }
    };

    loadTemplates();
  }, [location.search, authToken, dispatch, navigate]);

  const handleTemplateChange = (templateId) => {
    const chosenTemplateId = templateId;
    const chosenTemplate = templates.find((template) => template.id === chosenTemplateId);
    if (chosenTemplate) {
      setSelectedTemplateId(chosenTemplate.id);
      setTemplateDetails({
        templateType: chosenTemplate.templateType,
        templateFormat: chosenTemplate.templateFormat,
        templateData: chosenTemplate.templateData,
        templateId: chosenTemplate.id,
      });
    } else {
      // If no template is selected, clear the templateDetails
      setTemplateDetails({
        templateType: '',
        templateFormat: '',
        templateData: '',
        templateId: ''
      });
    }
  };

  const handleTemplateDataChange = (value) => {
    setTemplateDetails({...templateDetails, templateData: '', templateFormat: value});
    setGeneratedContent('');
  };

// Function to show the modal
  const showModal = () => {
    if (templateDetails.templateFormat !== '') {
      setIsModalVisible(true);
    } else {
      toast.error("Choose Template Format");
    }
  };

  const handlePreview = () => {
    setIsPreviewModalVisible(true);
  };

// Function to handle OK and make the API request
  const handleOk = async () => {
    setIsGenerating(true); // Show loading spinner
    try {
      // Call the API with templateDescription and handle the response
      const templateContent = await generateTemplateContent(templateDescription); // API request
      setGeneratedContent(templateContent);
      setTemplateDetails((prev) => ({
        ...prev,
        templateData: templateContent,
      }));

      setIsModalVisible(false); // Close modal on success
      setTemplateDescription(''); // Clear description input
    } catch (error) {
      toast.error('Failed to generate template. Please try again.'); // Show error if API fails
    } finally {
      setIsGenerating(false); // Stop loading spinner
    }
  };

// Example API call to generate template content
  const generateTemplateContent = async (description) => {
    try {
      const format = templateDetails.templateFormat;
      const response = await generateTemplateWithAI(description, format); // Replace with your network request
      return response.data; // Assuming the response contains the template content
    } catch (error) {
      toast.error('Failed to generate template.');
    }
  };

  const handleSave = async (addMore) => {
    // Validate required fields
    if (!templateDetails.templateType || !templateDetails.templateFormat || !templateDetails.templateData) {
      setError('All fields are required.'); // Set error message if fields are not filled
      return;
    }
    setError(''); // Clear error message if validation passes

    setIsLoading(true); // Set loading state to true

    try {
      await saveTemplate(templateDetails);
      toast.success('Template saved successfully.');
      if (addMore) {
        if (generatedContent) {
          setGeneratedContent('');
        }
        // Logic for saving and allowing the user to add more templates
        setTemplateDetails({templateType: '', templateFormat: '', templateData: ''}); // Clear fields for new template entry
        setIsCreatingNew(true);

      } else {
        // Logic for saving and moving to the next step
        navigate('/send', {state: {templateContent: templateDetails.templateData}}); // Pass templateData
      }
    } catch (error) {
      setError('Failed to save the template. Please try again.');
    } finally {
      setIsLoading(false); // Set loading state to false when done
    }
  };

  const handleNext = () => {
    // Navigate to home with the selected template
    navigate('/send', {
      state: {
        sentTemplateId: selectedTemplateId,
        sentTemplateContent: templateDetails.templateData
      }
    });
  };

  const handleCreateNew = () => {
    setIsCreatingNew(true);
    setSelectedTemplateId('');
    setTemplateDetails({
      templateType: '',
      templateFormat: '',
      templateData: '',
    });
  };

  const handleCancelCreateNew = () => {
    setIsCreatingNew(false);
    setTemplateDetails({
      templateType: '',
      templateFormat: '',
      templateData: '',
    });
    window.location.reload();
  };

  const handleEdit = () => {
    if (templateDetails.templateId) {
      navigate(`/edittemplate/${templateDetails.templateId}`);
    } else {
      toast.error('No Template Selected');
    }
  };

  const handleDelete = async () => {
    if (templateDetails.templateId) {
      try {
        // Call the deleteTemplate API to delete the template
        await deleteTemplate(templateDetails.templateId);

        // Remove the deleted template from the templates state
        setTemplates((prevTemplates) =>
          prevTemplates.filter((template) => template.id !== templateDetails.templateId)
        );

        // Reset the selected template and templateDetails state
        setSelectedTemplateId('');
        setTemplateDetails({
          templateType: '',
          templateFormat: '',
          templateData: '',
          templateId: ''
        });

        // Optionally, you can show a success message after deletion
        toast.success('Template Deleted Successfully');

      } catch (error) {
        toast.error('Failed to delete template. Please try again.');
      }
    } else {
      toast.error('No Template Selected');
    }
  };

  const renderTemplateContent = (text, isHTML = false) => {
    if (!text) return 'No template data available.';
    if (isHTML) {
      return (text);
    } else {
      return text;
    }
  };

  return (
    <section className='min-h-screen flex flex-col'>
      <ProfileHeader/>
      <div className='flex mulish-normal items-center my-auto justify-center'>
        <div className='bg-white rounded-xl p-4 w-full max-w-lg '>
          {!isCreatingNew && (
            <>
              {isFetching ? (
                <div className="flex items-center -mt-12 w-full justify-center flex-col gap-4">
                  <Skeleton.Input block active className="w-100 mt-2 h-4"/>
                  <Skeleton.Input active className="w-100 mt-2 h-4"/>
                  <Skeleton active paragraph={{rows: 0}} className='self-center mx-auto h-2 items-center mt-4'/>
                  <Skeleton.Button active className="w-100 h-4"/>
                </div>
              ) : (
                <>
                  {templates.length > 0 ? (
                    <>
                      {/* Select Existing Template */}
                      <div className='mb-4'>
                        <h3 className='text-lg text-center font-medium mb-2'>Select an existing template</h3>
                        <Select
                          value={selectedTemplateId}
                          onChange={handleTemplateChange}
                          className="w-full h-10 mt-2"
                          placeholder="Select Template"
                        >
                          <Select.Option value=''>Select Existing Template</Select.Option>
                          {templates?.map((template) => (
                            <Select.Option key={template.id} value={template.id}>
                              {template.templateType}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>

                      {/* Preview of Selected Template and Next Button */}
                      {selectedTemplateId && (
                        <div className='mb-4 mt-6'>

                          {/* Dropdown for Template Format */}
                          <label htmlFor='templateFormat' className='block mt-4 text-sm font-medium text-gray-700'>
                            Template Format
                          </label>
                          <select
                            id='templateFormat'
                            value={templateDetails.templateFormat}
                            onChange={(e) =>
                              setTemplateDetails({...templateDetails, templateFormat: e.target.value})
                            }
                            className='w-full mt-1 p-2 cursor-not-allowed border rounded-md bg-white' // Disabled-like appearance
                            disabled
                          >
                            <option value=''>Select Format</option>
                            <option value='text'>Text</option>
                            <option value='html'>HTML</option>
                          </select>
                          <div className='flex  mt-4 items-center justify-between'>
                            {/* Input for Template Content */}
                            <label htmlFor='templateData' className='block  text-sm font-medium text-gray-700'>
                              Template Content
                            </label>
                            <div className='flex gap-2'>
                              <Button
                                type="default"
                                icon={<DeleteOutlined/>}
                                onClick={handleDelete}
                                className="text-black px-2 rounded-md hover:bg-red-600"
                              >

                              </Button>
                              <Button
                                type='default'
                                icon={<EditOutlined/>}
                                onClick={() => handleEdit()}
                                className='text-black px-2 rounded-md hover:bg-green-600'
                              >
                              </Button>
                            </div>
                          </div>
                          {templateDetails.templateFormat.toLowerCase() === 'html' ? (
                            <div
                              id='templateData'
                              className='w-full mt-1 p-2 border rounded-md bg-white'
                              style={{maxHeight: '200px', overflowY: 'auto'}}
                              dangerouslySetInnerHTML={{__html: renderTemplateContent(templateDetails.templateData, true)}}
                            />
                          ) : (
                            <pre
                              id='templateData'
                              className='w-full mt-1 p-2 border rounded-md bg-white'
                              style={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                maxHeight: '200px',
                                overflowY: 'auto'
                              }}
                            >
    {renderTemplateContent(templateDetails.templateData, false)}
  </pre>
                          )}

                          {error && <p className='text-red-500 mt-2'>{error}</p>} {/* Show error message */}

                          <button
                            onClick={handleNext}
                            className='w-full bg-gray-200 text-black py-2 px-4 rounded-md hover:bg-gray-100 mt-2'
                          >
                            Next
                          </button>
                        </div>
                      )}

                      {/* "OR" Separator */}
                      <div className='flex items-center justify-center my-4'>
                        <hr className='w-1/4 border-gray-300'/>
                        <span className='mx-2 text-gray-500'>OR</span>
                        <hr className='w-1/4 border-gray-300'/>
                      </div>
                    </>
                  ) : null}

                  {/* Create New Template Button */}
                  <button
                    onClick={handleCreateNew}
                    className='w-full bg-black text-white py-2 px-4 rounded-md hover:bg-blue-600'
                  >
                    Create New Template
                  </button>
                </>
              )}

            </>
          )}

          {/* Create New Template Form */}
          {isCreatingNew && (
            <>
              <div className='flex gap-10 mb-6 w-full justify-start'>
                <button
                  onClick={handleCancelCreateNew}
                  className="text-xl self-start text-gray-700 hover:text-gray-900"
                >
                  <ArrowLeftOutlined/>
                </button>
                <p className='text-xl self-center text-center font-semibold'>Create Email Template</p>
              </div>
              <div className='mb-4 mt-10'>
                {/* Dropdown for Template Type */}
                <label htmlFor='templateType' className='block mt-2 text-sm font-medium text-gray-700'>
                  Template Title
                </label>
                <input
                  type='text'
                  id='templateType'
                  value={templateDetails.templateType}
                  onChange={(e) =>
                    setTemplateDetails({...templateDetails, templateType: e.target.value})
                  }
                  className='w-full mt-1 p-2 border rounded-md'
                  placeholder='Enter Template Title'
                />

                {/* Dropdown for Template Format */}
                <label htmlFor='templateFormat' className='block mt-4 text-sm font-medium text-gray-700'>
                  Template Format
                </label>
                <Select
                  id="templateFormat"
                  value={templateDetails.templateFormat}
                  onChange={(e) => handleTemplateDataChange(e)}
                  className="w-full h-10 mt-1"
                  placeholder="Select Format"
                >
                  <Select.Option value=''>Select Template Format</Select.Option>
                  <Select.Option value="text">Text</Select.Option>
                  <Select.Option value="html">HTML</Select.Option>
                </Select>

                {/* Input for Template Content */}
                <div className='flex justify-between items-center mb-2 mt-4'>
                  <label htmlFor='templateData' className='block text-sm font-medium text-gray-700'>
                    Template Content
                  </label>
                  <div className='flex items-center gap-2'>
                    {templateDetails.templateFormat === 'html' && generatedContent && (
                      <Button
                        onClick={handlePreview}
                        icon={<EyeOutlined/>}
                        className='ml-2 text-sm font-semibold bg-gray-100 text-black py-2 px-3 rounded-lg hover:bg-gray-200'
                      >
                        Preview
                      </Button>
                    )}

                    <Button
                      icon={generatedContent ? (<ReloadOutlined/>) : (<RobotOutlined/>)}
                      onClick={showModal}
                      className='text-sm font-semibold bg-gray-100 text-black pr-4 py-2 px-3 rounded-lg hover:bg-gray-200'
                    >
                      {generatedContent ? 'Regenerate' : 'Generate with AI'}
                    </Button>
                    <p className='bg-yellow-100 text-sm rounded-lg text-yellow-700 font-bold p-2'>Beta</p>
                  </div>
                </div>
                {generatedContent && templateDetails.templateFormat === 'html' ? (
                  <p className='p-2 bg-gray-100 m-1 rounded-lg text-sm'>Save to edit this template inside a code
                    editor</p>) : (
                  <div></div>)}
                <textarea
                  id='templateData'
                  value={templateDetails.templateData}
                  onChange={(e) =>
                    setTemplateDetails({...templateDetails, templateData: e.target.value})
                  }
                  className='w-full mt-1 p-2 border rounded-md'
                  rows='4'
                  placeholder='Enter template content...'
                />

                {error && <p className='text-red-500 mt-2'>{error}</p>} {/* Show error message */}
              </div>

              <div className='flex flex-col justify-between gap-2'>
                <button
                  onClick={() => handleSave(true)} // Save and Add More
                  className='w-full bg-white border border-black text-sm text-black py-2 px-4 rounded-lg hover:bg-blue-600'
                  disabled={isLoading} // Disable button while loading
                >
                  {isLoading ? 'Saving...' : 'Save and Add More'}
                </button>
                <button
                  onClick={() => handleSave(false)} // Save and Next
                  className='w-full bg-black text-sm text-white py-2 px-4 rounded-lg hover:bg-blue-600'
                  disabled={isLoading} // Disable button while loading
                >
                  {isLoading ? 'Saving...' : 'Save and Next'}
                </button>
              </div>

              {/* Cancel Button */}
              <button
                onClick={handleCancelCreateNew}
                className='w-full bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-100 mt-4'
              >
                Cancel
              </button>
            </>
          )}

          {isModalVisible && (
            <Modal
              title="Generate Template with AI"
              className='mulish-normal'
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={() => setIsModalVisible(false)}
              okText={isGenerating ? <LoadingOutlined/> : 'Generate'} // Loading spinner in button
              cancelText="Cancel"
              okButtonProps={{disabled: isGenerating}} // Disable button while generating
            >
              <div>
                <label htmlFor="templateDescription"
                       className="block mb-2 mt-4 mulish-normal text-sm font-medium text-gray-700">
                  Enter Template Description
                </label>
                <textarea
                  id="templateDescription"
                  value={templateDescription}
                  onChange={(e) => setTemplateDescription(e.target.value)}
                  className="w-full mulish-normal text-sm mt-1 p-2 leading-5 border rounded-md"
                  rows="4"
                  placeholder="A cold email template for reaching out to HR's"
                />
              </div>
            </Modal>

          )}

          {isPreviewModalVisible && (
            <Modal
              title="HTML Preview"
              visible={isPreviewModalVisible}
              onCancel={() => setIsPreviewModalVisible(false)}
              footer={null}
            >
              <div><p className='p-2 bg-gray-100 rounded-lg text-center m-2 mt-3 font-semibold'>Save to edit this HTML
                template.</p>
              </div>
              <div dangerouslySetInnerHTML={{__html: generatedContent}}/>
            </Modal>
          )}
        </div>
      </div>
    </section>
  );
};

export default ChooseTemplate;
