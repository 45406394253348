import React from 'react';
import {motion} from 'framer-motion';
import {testimonials} from '../assets/data/testimonials';

export default function TestimonialSection() {
  return (
    <section id='testimonials'
             className="mulish-normal rounded-xl max-w-7xl w-[95vw] flex mx-auto justify-center py-10 bg-gray-50 text-black">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl lg:text-4xl font-bold text-center mb-12">
          What Our Users Are Saying?
        </h2>

        <div className="flex flex-col lg:flex-row justify-between items-center gap-8">
          {testimonials.map((testimonial) => (
            <motion.div
              key={testimonial.id}
              className="bg-white p-6 rounded-lg lg:w-1/3"
              initial={{opacity: 0, y: 50}}
              whileInView={{opacity: 1, y: 0}}
              transition={{duration: 0.8}}
              viewport={{once: true}}
            >
              <div className="flex items-center mb-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div>
                  <h4 className="font-bold text-lg">{testimonial.name}</h4>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                </div>
              </div>
              <p className="text-gray-700">{testimonial.testimonial}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
