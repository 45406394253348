export const testimonials = [
  {
    id: 1,
    name: 'Aditya Mittal',
    role: 'Developer',
    testimonial:
      'FrostMail completely transformed our outreach process. We saved countless hours and saw a huge improvement in response rates!',
    image: 'https://randomuser.me/api/portraits/men/10.jpg',
  },
  {
    id: 2,
    name: 'Bharat Pahwa',
    role: 'Founder EngageGPT',
    testimonial:
      'I was blown away by how easy it was to generate hundreds of emails. FrostMail is now an integral part of our marketing strategy!',
    image: 'https://randomuser.me/api/portraits/men/4.jpg',
  },
  {
    id: 3,
    name: 'Utkarsh Garg',
    role: 'Head of Sales',
    testimonial:
      'So much Easy to send Mails. Efficient for us to close more deals. Can’t recommend FrostMail enough!',
    image: 'https://randomuser.me/api/portraits/men/7.jpg',
  }];