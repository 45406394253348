import React, {useEffect, useState} from 'react';
import {cancelScheduledEmail, fetchAllScheduledEmails} from '../network/History';
import {Button, Input, Skeleton, Table, Tag} from 'antd';
import ProfileHeader from '../components/Layout/ProfileHeader';
import {toast} from "react-toastify";

const ScheduledEmailsPage = () => {
  const [scheduledEmails, setScheduledEmails] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState('ALL'); // Filter by ALL, SENT, or SCHEDULED

  useEffect(() => {
    fetchScheduledEmails();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchQuery, scheduledEmails, filterType]);

  const fetchScheduledEmails = async () => {
    setLoading(true);
    try {
      const data = await fetchAllScheduledEmails();
      const sortedData = data.sort(
        (a, b) => new Date(b.scheduleDateTime) - new Date(a.scheduleDateTime)
      );
      setScheduledEmails(sortedData);
      setFilteredEmails(sortedData);
    } catch (error) {
      console.error('Error fetching scheduled emails:', error);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    let filtered = scheduledEmails;

    // Apply search filter (recipient email or subject)
    if (searchQuery) {
      filtered = filtered.filter(
        (email) =>
          email.recipientEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
          email.subject.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply status filter (ALL, SENT, SCHEDULED)
    if (filterType !== 'ALL') {
      filtered = filtered.filter((email) => email.status === filterType);
    }

    setFilteredEmails(filtered);
  };

  const handleCancelEmail = async (emailId) => {
    try {
      setLoading(true);
      await cancelScheduledEmail(emailId);
      toast.success('Scheduled email canceled successfully!');
      fetchScheduledEmails(); // Refresh the list
    } catch (error) {
      console.error('Error canceling scheduled email:', error);
      toast.error('Failed to cancel the scheduled email.');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Recipient Email',
      dataIndex: 'recipientEmail',
      key: 'recipientEmail',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Scheduled Date & Time',
      dataIndex: 'scheduleDateTime',
      key: 'scheduleDateTime',
      render: (date) => (date ? new Date(date).toLocaleString() : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const color = status === 'SCHEDULED' ? 'blue' : 'green';
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) =>
        record.status === 'SCHEDULED' && (
          <Button danger onClick={() => handleCancelEmail(record.id)}>
            Cancel
          </Button>
        ),
    },
  ];

  return (
    <>
      <ProfileHeader/>
      <div className="p-6">
        {loading ? (
          <Skeleton active paragraph={{rows: 6}}/>
        ) : (
          <>
            {/* Dashboard Section */}
            <div className="mulish-normal w-full gap-4 flex flex-wrap justify-between mb-6">
              <div className="flex-1 min-w-[120px] bg-gray-100 text-black text-center p-4 rounded-lg">
                <h3 className="text-lg font-medium">Total Emails</h3>
                <h2 className="text-2xl font-bold">{scheduledEmails.length}</h2>
              </div>
              <div className="flex-1 min-w-[120px] bg-gray-100 text-black text-center p-4 rounded-lg">
                <h3 className="text-lg font-medium">Scheduled</h3>
                <h2 className="text-2xl font-bold">
                  {scheduledEmails.filter((email) => email.status === 'SCHEDULED').length}
                </h2>
              </div>
              <div className="flex-1 min-w-[120px] bg-gray-100 text-black text-center p-4 rounded-lg">
                <h3 className="text-lg font-medium">Sent</h3>
                <h2 className="text-2xl font-bold">
                  {scheduledEmails.filter((email) => email.status === 'SENT').length}
                </h2>
              </div>
            </div>

            <div className="flex lg:flex-row flex-col  mulish-normal justify-between items-center mb-4">
              <h2 className="text-lg lg:text-xl mb-2">Scheduled Emails</h2>
              <div className="space-x-4">
                {/* Search Filter */}
                <Input
                  placeholder="Search email or subject"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="lg:w-60 w-40"
                />

                {/* Status Filter */}
                <select
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  className="p-2 border rounded"
                >
                  <option value="ALL">All</option>
                  <option value="SCHEDULED">Scheduled</option>
                  <option value="SENT">Sent</option>
                </select>
              </div>
            </div>
            <Table
              dataSource={filteredEmails}
              columns={columns}
              rowKey={(record) => record.emailHistoryId}
              loading={loading}
              className='overflow-x-scroll'
              pagination={{pageSize: 10}}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ScheduledEmailsPage;

