import React from 'react';
import {useDispatch} from 'react-redux';
import {logoutAction} from '../redux/auth/authActions';
import {useNavigate} from 'react-router-dom';
import {LogoutOutlined} from "@ant-design/icons";

const LogoutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutAction());
    navigate('/login');
  };

  return (
    <button
      onClick={handleLogout}
      className="bg-red-500 flex gap-2 items-center mt-2 text-white w-full px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
    >
      <LogoutOutlined/> Logout
    </button>
  );
};

export default LogoutButton;
