import Cookies from 'js-cookie';
import {login, signup} from '../../network/Auth';
import {encodeToken} from '../../utils/tokenUtils';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const LOGOUT = 'LOGOUT';

export const setAuthTokenAction = (token) => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

export const logoutAction = () => {
  Cookies.remove('cold-emails');
  return {
    type: LOGOUT,
  };
};

// Thunk Action for Signup
export const handleSignup = (email, password) => async (dispatch) => {
  try {
    const response = await signup(email, password);
    const token = response.token;
    // Encode the token before saving it as a cookie
    const encodedToken = encodeToken(token);
    Cookies.set('cold-emails', encodedToken, {expires: 3, secure: true, sameSite: 'strict'});

    // Dispatch action to save token in Redux store (you can save the plain token in Redux)
    dispatch(setAuthTokenAction(token));

    return token;
  } catch (error) {
    // Retrieve a standardized error message
    const errorMessage = error.message;

    // Optionally, you can log the error or perform other side effects here
    console.error('Signup Error:', errorMessage);

    // Throw the error to be handled in the component (e.g., setFieldError in Formik)
    throw new Error(errorMessage);
  }
};

// Thunk Action for Login
export const handleLogin = (email, password) => async (dispatch) => {
  try {
    const response = await login(email, password);
    const token = response.token;

    // Encode the token before saving it as a cookie
    const encodedToken = encodeToken(token);

    Cookies.set('cold-emails', encodedToken, {expires: 3, secure: true, sameSite: 'strict'});

    // Dispatch action to save token in Redux store
    dispatch(setAuthTokenAction(token));

    return token;
  } catch (error) {
    // Retrieve a standardized error message
    const errorMessage = error.message;

    // Optionally, you can log the error or perform other side effects here
    console.error('Login Error:', errorMessage);

    // Throw the error to be handled in the component (e.g., setFieldError in Formik)
    throw new Error(errorMessage);
  }
};

