import React from 'react';
import {Avatar, Dropdown, Layout, Menu} from 'antd';
import {FileOutlined, HistoryOutlined, MailOutlined, UserOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import FrostMailLogo from '../../assets/images/NamedLogo.png'
import '../../assets/Styles/navbar.css';
import LogoutButton from "../Logout";

const {Header} = Layout;

const ProfileHeader = () => {
  const profileMenu = (
    <Menu>
      <Menu.Item key="1" icon={<FileOutlined/>}>
        <Link to="/template">My Templates</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<MailOutlined/>}>
        <Link to="/send">Send Mail</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<HistoryOutlined/>}>
        <Link to="/history">History</Link>
      </Menu.Item>
      <div className='self-center w-full'>
        <LogoutButton/>
      </div>
    </Menu>
  );

  return (
    <Header className="navbar lg:px-4 px-3 py-2">
      <div className="logo">
        <Link to="/"><img className='h-10' src={FrostMailLogo} alt='FrostMailLogo'/> </Link>
      </div>
      <div className="profile-avatar">
        <Dropdown overlay={profileMenu} placement="bottomRight">
          <Avatar icon={<UserOutlined/>} style={{cursor: 'pointer'}}/>
        </Dropdown>
      </div>
    </Header>
  );
};

export default ProfileHeader;
