import React from 'react';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom'; // Assuming you're using React Router for navigation
import {featuresData} from '../assets/data/featureData.js'

export default function FeaturesSection() {
  return (
    <div id='features' className="mulish-normal w-full text-black">
      <p className='text-center font-bold text-3xl lg:text-4xl mb-14 lg:mb-14 text-black'> Features</p>
      <div className="max-w-6xl mx-auto px-4">
        {featuresData.map((feature) => (
          <div
            key={feature.id}
            className={`flex flex-col lg:flex-row items-center lg:mb-16 mb-10 ${
              feature.reverse ? 'lg:flex-row-reverse' : ''
            }`}
          >
            {/* Image Section */}
            <motion.div
              className={`lg:w-1/2 w-full flex ${
                feature.reverse ? ' justify-center lg:justify-center' : ' justify-center lg:justify-center'
              }`}
              initial={{opacity: 0, x: feature.reverse ? 20 : 20}}
              whileInView={{opacity: 1, x: 0}}
              transition={{duration: 0.8}}
              viewport={{once: true}}
            >
              <img
                className="lg:w-1/2 h-3/4 w-3/4 lg:h-1/2"
                src={feature.image}
                alt={feature.title}
                width={feature.width}
                height={feature.height}
              />
            </motion.div>

            {/* Text Section */}
            <motion.div
              className="lg:w-1/2 w-full border flex bg-gray-50 rounded-xl p-4 lg:h-full flex-col text-center lg:mt-0 mt-8 lg:text-left"
              initial={{opacity: 0, x: feature.reverse ? 20 : 20}}
              whileInView={{opacity: 1, x: 0}}
              transition={{duration: 0.8}}
              viewport={{once: true}}
            >
              <h2 className="text-xl font-semibold lg:text-2xl text-left leading-snug">
                {feature.title}
              </h2>
              <p className="text-md lg:text-lg text-gray-700 text-left mt-4">
                {feature.description}
              </p>

              {/* Learn More Button */}
              <motion.div
                className="mt-6"
                initial={{x: -10, opacity: 0}}
                animate={{x: 0, opacity: 1}}
                transition={{duration: 0.5}}
              >
                <Link to="/login">
                  <button
                    className="flex border items-center p-1 lg:px-4 px-3 bg-white rounded-md text-gray-600 font-semibold text-md">
                    Learn More
                  </button>
                </Link>
              </motion.div>
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
}
