export const privacySections = [
  {
    id: 1,
    title: 'Introduction',
    content:
      'At FrostMail, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices regarding the collection, use, and protection of your data when you use our services.',
  },
  {
    id: 2,
    title: 'Information We Collect',
    content:
      'We may collect the following types of information from you: personal details such as name, email, and contact information; usage data including cookies and analytics information; and any other data you provide when using our services.',
  },
  {
    id: 3,
    title: 'How We Use Your Information',
    content:
      'We use the information we collect to provide, operate, and improve our services, to communicate with you, and to ensure the security of our platform. We may also use your information for marketing and promotional purposes with your consent. We explicitly affirm that Google Workspace APIs are not used to develop, improve, or train generalized AI and/or ML models.',
  },
  {
    id: 4,
    title: 'Data Sharing and Disclosure',
    content:
      'We do not sell your personal information to third parties. However, we may share data with trusted third-party service providers who help us deliver our services, or in the event of a legal requirement such as a subpoena or law enforcement request.',
  },
  {
    id: 5,
    title: 'Security of Your Information',
    content:
      'We take the security of your data seriously and use industry-standard measures such as encryption and secure data storage. However, no method of transmission over the internet or method of electronic storage is 100% secure.',
  },
  {
    id: 6,
    title: 'Cookies and Tracking Technologies',
    content:
      'We use cookies and similar technologies to improve your experience on our site, track usage, and deliver personalized content. You can choose to disable cookies through your browser settings, though this may affect certain functionalities of our services.',
  },
  {
    id: 7,
    title: 'Your Data Rights',
    content:
      'You have the right to access, update, or delete your personal information. You may also have the right to object to the processing of your data or request data portability in certain circumstances.',
  },
  {
    id: 8,
    title: 'Changes to Our Privacy Policy',
    content:
      'We may update this Privacy Policy from time to time to reflect changes in our practices. We will notify you of any significant changes by updating the date at the top of this policy and, where required, providing additional notice.',
  },
  {
    id: 9,
    title: 'Contact Us',
    content:
      'If you have any questions or concerns about this Privacy Policy, please contact us at frostmail.help@gmail.com',
  },
];
