import React from 'react';
import {motion} from 'framer-motion';
import {termsSections} from "../assets/data/termsofService";
import FrostMailLogo from '../assets/images/NamedLogo.png'

export default function TermsOfService() {
  return (
    <>
      <img src={FrostMailLogo} className='h-16 mx-auto mt-4' alt='FrostMailLogo'/>
      <div className="mulish-normal w-full text-black pt-16 pb-16">

        <motion.div
          className="text-center mb-14"
          initial={{opacity: 0, y: 20}}
          animate={{opacity: 1, y: 0}}
          transition={{duration: 0.8}}
        >
          <p className="text-4xl text-black pt-0 font-bold">Terms of Service</p>
          <p className="text-lg text-gray-700 mt-2">Effective Date: 21<sup>st</sup> Oct,2024</p>
        </motion.div>
        <div className="max-w-5xl mx-auto px-4">
          {termsSections.map((section) => (
            <motion.div
              key={section.id}
              className="mb-10"
              initial={{opacity: 0, x: 20}}
              whileInView={{opacity: 1, x: 0}}
              transition={{duration: 0.8}}
              viewport={{once: true}}
            >
              <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
              <p className="text-lg text-gray-700">{section.content}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
}
