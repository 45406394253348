import React from 'react';
import {useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import HeroGif from '../assets/images/HeroGif.png'
import SendGif from '../assets/images/SendGif.gif'
import '../assets/Styles/GlobalCSS.css'

export default function HeroSection() {

  const navigate = useNavigate(); // Use navigate for redirection

  const handleGetStarted = () => {
    navigate('/login'); // Redirect to the signup page
  };

  return (
    <div id='home' className="flex flex-col justify-center items-center bg-white text-black p-6">
      <div className="flex lg:flex-row flex-col justify-between w-full max-w-6xl">
        {/* Left Text Section */}
        <div className="lg:w-1/2 lg:mt-10 mt-28 flex flex-col gap-4 justify-between">
          <h1
            className="mulish-normal lg:text-5xl lg:leading-[4.5rem] leading-[3rem] text-3xl"
          >
            Send Personalized Cold Emails in Seconds
          </h1>

          <motion.h2
            className="lg:text-2xl w-fit rounded-md mulish text-lg lg:mt-0 -mt-2 leading-8 text-black"
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8, delay: 0.4}}
          >
            Maximum Outreach, Minimum Effort
          </motion.h2>
          <div className='flex gap-1 items-end'>

            <motion.button
              whileHover={{scale: 1.05}}
              whileTap={{scale: 0.95}}
              onClick={handleGetStarted}
              className="mulish-normal bg-black text-white py-2 px-4 transition-all hover:rounded-md text-lg rounded-md shadow font-semibold w-fit"
            >
              Get Started
            </motion.button>
            <motion.div
              className="flex justify-center items-center"
              initial={{opacity: 0, y: -20}}
              animate={{opacity: 1, y: 0}}
              transition={{duration: 0.8, delay: 0.4}}
            >
              <img
                className='lg:w-20 w-18 lg:h-16 h-16'
                src={SendGif}
                alt="Design GIF"
              />
            </motion.div>
          </div>
        </div>

        {/* Right GIF Section */}
        <motion.div
          className="lg:w-1/2 flex justify-center items-center"
          initial={{opacity: 0, y: 300, x: -300, scale: 0.9}}
          animate={{opacity: 1, y: 0, x: 0, scale: 1}}
          transition={{duration: 0.8, delay: 0.6}}
        >
          <img
            className='-rotate-12 w-56 lg:w-auto lg:mt-0 mt-10 lg:h-full '
            src={HeroGif}  // Replace with the actual GIF path
            alt="Design GIF"
          />
        </motion.div>

      </div>
    </div>
  );
}
