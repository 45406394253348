import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import ChooseTemplate from './pages/ChooseTemplate';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './pages/LandingPage';
import Home from './pages/Home';
import Login from './pages/Login';
import EditTemplate from "./pages/EditTemplate";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import {Analytics} from "@vercel/analytics/react"
import NotFound from "./pages/NotFound";
import EmailHistoryPage from './pages/EmailHistory';
import ProtectedRoute from "./components/ProtectedRoute";
import ScheduledEmailsPage from "./pages/ScheduleHistory";

function App() {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Analytics/>
      <div className='App'>
        <Routes>
          <Route path='/login' element={<Login/>}/>
          <Route path='/' element={<LandingPage/>}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
          <Route path='/terms-of-service' element={<TermsOfService/>}/>
          <Route path='/template' element={<ChooseTemplate/>}/>
          <Route path='*' element={<NotFound/>}/>
          <Route
            path='/send'
            element={
              <ProtectedRoute>
                <Home/>
              </ProtectedRoute>
            }
          />
          <Route
            path='/history'
            element={
              <ProtectedRoute>
                <EmailHistoryPage/>
              </ProtectedRoute>
            }
          />
          <Route
            path='/edittemplate/:id'
            element={
              <ProtectedRoute>
                <EditTemplate/>
              </ProtectedRoute>
            }
          />

          <Route
            path='/scheduled'
            element={
              <ProtectedRoute>
                <ScheduledEmailsPage/>
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>

  );
}

export default App;