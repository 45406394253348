import React from 'react';
import Navbar from '../components/Layout/NavBar';
import HeroSection from "../components/HeroSection";
import FeaturesSection from "../components/Features";
import FAQSection from "../components/FAQ";
import Footer from "../components/Layout/Footer";
import TestimonialSection from "../components/Testimonials";

const LandingPage = () => {

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };
  return (
    <div>
      <section id='home' className='flex flex-col min-h-screen'>
        <Navbar scrollToSection={scrollToSection}/>
        <div className='my-auto mx-auto self-center'>
          <HeroSection/>
        </div>
      </section>
      <FeaturesSection/>
      <TestimonialSection/>
      <FAQSection/>
      <Footer/>
    </div>
  );
};

export default LandingPage;
