import axiosInstance from './axiosConfig'; // Adjust the import based on your file structure
import {getErrorMessage} from '../utils/errorHandler';

// Function to save a template
export const saveTemplate = async (templateDetails) => {
  try {
    const response = await axiosInstance.post('/templates/save', templateDetails, {});
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to save a template
export const savePlaceholders = async (templateId, placeholders) => {
  try {
    const response = await axiosInstance.post(
      `/templates/placeholders/${encodeURIComponent(templateId)}`, // Ensure templateId is correctly encoded
      {placeholders} // Send placeholders as the request body, not as a URL param
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to fetch user templates
export const fetchUserTemplates = async () => {
  try {
    const response = await axiosInstance.get('/templates');
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to send a single email with attachments
export const sendEmail = async (template) => {
  try {
    const formData = new FormData();
    formData.append('templateFormat', template.templateFormat);
    formData.append('subject', template.subject);
    formData.append('recipientEmail', template.recipientEmail);
    formData.append('templateData', template.templateData);
    template.attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    const response = await axiosInstance.post('/email/google', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to send a scheduled mail
export const sendScheduledEmail = async (scheduleDateTime, template) => {
  try {
    const formData = new FormData();

    // Append the template and scheduling data
    formData.append('templateFormat', template.templateFormat);
    formData.append('subject', template.subject);
    formData.append('recipientEmail', template.recipientEmail);
    formData.append('templateData', template.templateData);
    formData.append('scheduleDateTime', scheduleDateTime);
    // Attach files if any
    template.attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    // Make the POST request to schedule the email
    const response = await axiosInstance.post('/email/schedule', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to send multiple emails
export const sendMultipleEmails = async (templates) => {
  try {
    const formData = new FormData();

    // Loop through all the templates and append them to the FormData
    templates.forEach((template, index) => {
      formData.append(`email[${index}].templateFormat`, template.templateFormat);
      formData.append(`email[${index}].subject`, template.subject);
      formData.append(`email[${index}].recipientEmail`, template.recipientEmail);
      formData.append(`email[${index}].templateData`, template.templateData);
      template.attachments.forEach((file, attachmentIndex) => {
        formData.append(`email[${index}].attachments[${attachmentIndex}]`, file);
      });
    });

    const response = await axiosInstance.post('/email/google/multiple', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to schedule all emails
export const scheduleAllEmails = async (templates, scheduleDateTime) => {
  try {
    if (!scheduleDateTime) {
      throw new Error('No scheduled date/time selected');
    }

    const formData = new FormData();

    // Loop through all the templates and append them to the FormData with the scheduled date/time
    templates.forEach((template, index) => {
      formData.append(`email[${index}].templateFormat`, template.templateFormat);
      formData.append(`email[${index}].subject`, template.subject);
      formData.append(`email[${index}].recipientEmail`, template.recipientEmail);
      formData.append(`email[${index}].templateData`, template.templateData);
      formData.append(`email[${index}].scheduleDateTime`, template.scheduleDateTime);

      // Append attachments if any
      template.attachments.forEach((file, attachmentIndex) => {
        formData.append(`email[${index}].attachments[${attachmentIndex}]`, file);
      });
    });

    // Send the form data to the backend to schedule all emails
    const response = await axiosInstance.post('/email/schedule/multiple', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw new Error(`Failed to schedule all emails: ${getErrorMessage(error)}`);
  }
};

// Function to send Sample email with attachments
export const sendSampleEmail = async (template, UserEmail) => {
  try {
    const formData = new FormData();
    formData.append('templateFormat', template.templateFormat);
    formData.append('subject', template.subject);
    formData.append('recipientEmail', UserEmail);
    formData.append('templateData', template.templateData);
    template.attachments?.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    const response = await axiosInstance.post('/email/google', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to fetch template by TemplateID
export const fetchTemplateById = async (templateId) => {
  try {
    const response = await axiosInstance.get(`/templates/${encodeURIComponent(templateId)}`);
    return response.data.data; // Adjust based on your API response structure
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to update an existing template
export const updateTemplate = async (templateDetails) => {
  try {
    const {id, ...data} = templateDetails; // Extract the ID from the template details
    const response = await axiosInstance.put(`/templates/${encodeURIComponent(id)}`, data);
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
}

// Function to generate a template using AI
export const generateTemplateWithAI = async (templateDescription, templateFormat) => {
  try {
    const response = await axiosInstance.post('/templates/generate', {
      description: templateDescription,
      format: templateFormat
    });
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to delete a template
export const deleteTemplate = async (templateId) => {
  try {
    const response = await axiosInstance.delete(`/templates/${encodeURIComponent(templateId)}`);
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error)); // Handle errors as needed
  }
}