import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {fetchAllEmailHistory} from '../network/History';
import {Input, Select, Skeleton, Table, Tag} from 'antd';
import ProfileHeader from "../components/Layout/ProfileHeader";

const {Option} = Select;

const EmailHistoryPage = () => {
  const [emailHistory, setEmailHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [counts, setCounts] = useState({sent: 0, scheduled: 0, failed: 0, canceled: 0});
  const navigate = useNavigate();

  useEffect(() => {
    fetchHistory();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filterStatus, searchQuery, emailHistory]);

  const fetchHistory = async () => {
    setLoading(true);
    try {
      const data = await fetchAllEmailHistory();
      const sortedData = data.sort((a, b) => new Date(b.sentDate) - new Date(a.sentDate));
      setEmailHistory(sortedData);
      setFilteredHistory(sortedData);
      calculateCounts(sortedData);
    } catch (error) {
      console.error('Error fetching email history:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateCounts = (data) => {
    const sent = data.filter((email) => email.status === 'SENT').length;
    const scheduled = data.filter((email) => email.status === 'SCHEDULED').length;
    const failed = data.filter((email) => email.status === 'FAILED').length;
    const canceled = data.filter((email) => email.status === 'CANCELLED').length;
    setCounts({sent, scheduled, failed, canceled});
  };

  const applyFilters = () => {
    let filtered = emailHistory;

    if (filterStatus) {
      filtered = filtered.filter((email) => email.status === filterStatus);
    }

    if (searchQuery) {
      filtered = filtered.filter(
        (email) =>
          email.recipientEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
          email.subject.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredHistory(filtered);
  };

  const handleFilterChange = (value) => {
    if (value === 'SCHEDULED') {
      navigate('/scheduled');
    } else {
      setFilterStatus(value);
      if (window.location.pathname !== '/history') {
        navigate('/history');
      }
    }
  };

  const columns = [
    {
      title: 'Recipient Email',
      dataIndex: 'recipientEmail',
      key: 'recipientEmail',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const color = status === 'SENT' ? 'green' : status === 'FAILED' ? 'red' : 'blue';
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: 'Sent Date',
      dataIndex: 'sentDate',
      key: 'sentDate',
      render: (date) => (date ? new Date(date).toLocaleString() : '-'),
    },
  ];

  return (
    <>
      <ProfileHeader/>
      <div className="p-6">
        {loading ? (
          <Skeleton active paragraph={{rows: 6}}/>
        ) : (
          <>
            {/* Dashboard */}
            <div className="mulish-normal w-full gap-4 flex flex-wrap justify-between mb-6">
              <div className="flex-1 min-w-[120px] bg-gray-100 text-black text-center p-4 rounded-lg">
                <h3 className="text-lg font-medium">Sent</h3>
                <h2 className="text-2xl font-bold">{counts.sent}</h2>
              </div>
              <div className="flex-1 min-w-[120px] bg-gray-100 text-black text-center p-4 rounded-lg">
                <h3 className="text-lg font-medium">Scheduled</h3>
                <h2 className="text-2xl font-bold">{counts.scheduled}</h2>
              </div>
              <div className="flex-1 min-w-[120px] bg-gray-100 text-black text-center p-4 rounded-lg">
                <h3 className="text-lg font-medium">Failed</h3>
                <h2 className="text-2xl font-bold">{counts.failed}</h2>
              </div>
              <div className="flex-1 min-w-[120px] bg-gray-100 text-black text-center p-4 rounded-lg">
                <h3 className="text-lg font-medium">Cancelled</h3>
                <h2 className="text-2xl font-bold">{counts.canceled}</h2>
              </div>
            </div>


            <div className="flex flex-col gap-2 lg:flex-row mulish-normal justify-between items-center mb-4">
              <h2 className="lg:text-xl text-lg font-bold">Email History</h2>
              <div className="flex space-x-4">
                {/* Status Filter */}
                <Select
                  value={filterStatus}
                  onChange={handleFilterChange}
                  className="w-36"
                >
                  <Option value="">All</Option>
                  <Option value="SENT">Sent</Option>
                  <Option value="SCHEDULED">Scheduled</Option>
                  <Option value="FAILED">Failed</Option>
                  <Option value="CANCELLED">Cancelled</Option>
                </Select>

                {/* Search Filter */}
                <Input
                  placeholder="Search email or subject"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="lg:w-60 w-40"
                />
              </div>
            </div>
            <Table
              className="overflow-x-scroll"
              dataSource={filteredHistory}
              columns={columns}
              rowKey={(record) => record.id}
              loading={loading}
              pagination={{pageSize: 10}}
            />
          </>
        )}
      </div>
    </>
  );
};

export default EmailHistoryPage;
