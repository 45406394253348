import React from 'react';
import Logo from '../../assets/images/WhiteLogo.png';

// Scroll to section handler
const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({behavior: 'smooth'});
  }
};

export default function Footer() {
  return (
    <footer className="mulish-normal relative w-full bg-black text-white py-8">
      {/* Large Background Text */}

      <div className="max-w-7xl mx-auto px-4 relative z-10">
        <div className="flex flex-col items-center justify-center text-center md:flex-row md:justify-between">
          {/* Logo Section */}
          <div className="md:mb-0">
            <img src={Logo} alt="logo" className="h-12 lg:h-16 self-start w-full"/>
          </div>

          {/* Navigation Links */}
          <div
            className="flex p-3 w-full lg:w-min text-[16px] lg:text-lg flex-col items-start lg:items-center space-y-2 md:flex-row md:space-y-0 md:space-x-6">
            <button
              className="text-white hover:text-gray-400 focus:outline-none"
              onClick={() => scrollToSection('home')}
            >
              Home
            </button>
            <button
              className="text-white hover:text-gray-400 focus:outline-none"
              onClick={() => scrollToSection('features')}
            >
              Features
            </button>
            <button
              className="text-white hover:text-gray-400 focus:outline-none"
              onClick={() => scrollToSection('testimonials')}
            >
              Testimonials
            </button>
            <button
              className="text-white hover:text-gray-400 focus:outline-none"
              onClick={() => scrollToSection('faqs')}
            >
              FAQs
            </button>
          </div>

          {/* Legal Links */}
          <div
            className="flex p-3 flex-col lg:w-fit w-full  text-[16px] lg:text-lg  lg:mt-0 lg:items-center  md:mt-4 mt-0 items-start space-y-2 md:flex-row md:space-y-0 md:space-x-6">
            <a
              href="/terms-of-service"
              className="text-white hover:text-gray-400"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            <a
              href="/privacy-policy"
              className="text-white hover:text-gray-400"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="mt-8 text-center text-lg text-white relative z-10">
        <p className='text-white lg:text-xl text-lg'> Developed by <a className='font-bold'
                                                                      href='https://www.linkedin.com/in/bharat-pahwa/'> Bharat
          Pahwa</a> & <a className='font-bold' href='https://www.linkedin.com/in/adityamittal18/'> Aditya Mittal </a>
        </p>
      </div>
      <div className="mt-8 flex flex-col text-center text-sm text-gray-500 relative z-10">
        <span className='text-white text-lg mb-2'> frostmail.help@gmail.com</span>
        © {new Date().getFullYear()} FrostMail. All Rights Reserved.
      </div>
      <div className="absolute inset-0 lg:top-[0%] top-[60%] flex items-center justify-center">
        <h1 className="text-[3.5rem] lg:text-[12rem] font-bold opacity-10 text-white pointer-events-none">
          FROSTMAIL
        </h1>
      </div>
    </footer>
  );
}
