export const termsSections = [
  {
    id: 1,
    title: 'Introduction',
    content:
      'Welcome to FrostMail! By using our services, you agree to these Terms of Service. Please read them carefully before accessing or using our platform.',
  },
  {
    id: 2,
    title: 'Acceptance of Terms',
    content:
      'By accessing or using our services, you agree to be bound by these Terms of Service and any other applicable policies. If you do not agree, do not use our services.',
  },
  {
    id: 3,
    title: 'Changes to Terms',
    content:
      'We reserve the right to modify or update these Terms of Service at any time. Any changes will be posted on this page, and the effective date will be updated accordingly.',
  },
  {
    id: 4,
    title: 'Account Creation and Responsibility',
    content:
      'To use certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities under your account.',
  },
  {
    id: 5,
    title: 'User Conduct',
    content:
      'You agree to use our services only for lawful purposes. You are prohibited from engaging in any conduct that could damage, disable, or impair our platform or interfere with other users’ access.',
  },
  {
    id: 6,
    title: 'Intellectual Property',
    content:
      'All content, trademarks, and intellectual property rights associated with our services belong to FrostMail or its licensors. You may not use, copy, or distribute any content without proper authorization.',
  },
  {
    id: 7,
    title: 'Privacy and Data Collection',
    content:
      'By using our services, you consent to the collection and use of your personal information as described in our Privacy Policy.',
  },
  {
    id: 8,
    title: 'Limitations of Liability',
    content:
      'We are not liable for any indirect, incidental, or consequential damages arising from the use or inability to use our services. Our total liability is limited to the amount you paid for the service.',
  },
  {
    id: 9,
    title: 'Termination of Access',
    content:
      'We reserve the right to suspend or terminate your access to our platform if we believe you have violated these Terms of Service or engaged in inappropriate conduct.',
  },
  {
    id: 10,
    title: 'Governing Law',
    content:
      'These Terms of Service shall be governed by and construed in accordance with the laws of New Delhi. Any disputes will be resolved in the courts of New Delhi.',
  },
  {
    id: 11,
    title: 'Dispute Resolution',
    content:
      'Any disputes related to these Terms of Service will be resolved through arbitration, rather than in court. By using our services, you agree to arbitrate any disputes that arise.',
  },
  {
    id: 12,
    title: 'Contact Us',
    content:
      'If you have any questions about these Terms of Service, please contact us at frostmail.help@gmail.com',
  },
];