import React, {useState} from 'react';
import {motion} from 'framer-motion';
import {faqs} from '../assets/data/faqs.js'

export default function FAQSection() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div id='faqs' className="mulish-normal w-full py-10 bg-white">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold lg:text-4xl text-center mb-12">
          Frequently Asked Questions
        </h2>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="bg-gray-50 rounded-xl my-3 px-3 py-3"
            onClick={() => toggleFAQ(index)}
          >
            <motion.div
              initial={false}

              className="cursor-pointer flex justify-between items-center"
            >
              <h3 className="text-md mr-2 lg:text-xl font-semibold">{faq.question}</h3>
              <motion.span
                initial={false}
                className="text-xl"
              >
                {activeIndex === index ? '-' : '+'}
              </motion.span>
            </motion.div>

            {activeIndex === index && (
              <motion.div
                initial={{opacity: 0, height: 0}}
                animate={{opacity: 1, height: 'auto'}}
                transition={{duration: 0.3}}
                className="text-gray-700 mt-4"
              >
                <p className="text-md lg:text-lg">{faq.answer}</p>
              </motion.div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
