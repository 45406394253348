import React, {useState} from 'react';
import logo from '../../assets/images/NamedLogo.png';
import ProfileHeader from './ProfileHeader';
import {useSelector} from "react-redux"; // Assuming you have a ProfileHeader component

const NavBar = ({scrollToSection}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {token} = useSelector((state) => state.auth);
  // On component mount, check if token is available in localStorage
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (sectionId) => {
    scrollToSection(sectionId); // Scroll to the selected section
    setIsOpen(false); // Close the mobile menu
  };

  // Conditionally render based on token presence
  if (token) {
    return (<ProfileHeader/>);
  }
  return (
    <nav
      className='p-4 mulish-normal self-center justify-between fixed w-full text-black rounded-xl bg-white/75 flex b items-center z-50 backdrop-blur-lg'>
      <div className='flex items-center'>
        <img src={logo} alt='Logo' className='h-10'/>
      </div>

      {/* Mobile Menu Button */}
      <div className='lg:hidden'>
        <button
          onClick={toggleMenu}
          className='text-black hover:bg-blue-50  focus:outline-none focus:bg-gray-50 p-2 pl-3 pr-3 rounded-md'
        >
          <svg
            className='h-6 w-6 fill-current'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            {isOpen ? (
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4 6h16v1H4V6zm0 5h16v1H4v-1zm16 4H4v1h16v-1z'
              />
            ) : (
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4 6h16v2H4v-2zm0 5h16v2H4v-2zm16 5H4v2h16v-2z'
              />
            )}
          </svg>
        </button>
      </div>

      {/* Desktop Menu Items */}
      <div className='hidden pr-3 lg:flex lg:items-center lg:space-x-0'>
        <button
          onClick={() => handleMenuItemClick('home')}
          className='text-base text-black hover:bg-gray-100  p-2 px-3 rounded-lg'
        >
          Home
        </button>
        <button
          onClick={() => handleMenuItemClick('features')}
          className='text-base text-black hover:bg-gray-100 transition-all focus:outline-none p-2 pl-3 px-3 rounded-lg'
        >
          Features
        </button>
        <button
          onClick={() => handleMenuItemClick('testimonials')}
          className='text-base text-black hover:bg-gray-100 transition-all  focus:outline-none p-2 pl-3 px-3 rounded-lg'
        >
          Testimonials
        </button>
        <button
          onClick={() => handleMenuItemClick('faqs')}
          className='text-base text-black hover:bg-gray-100 transition-all  focus:outline-none p-2 pl-3 px-3 rounded-lg'
        >
          FAQs
        </button>
      </div>

      <a
        href='https://www.youtube.com/watch?v=jpj5SYu28b0'
        target='_blank'
        className='hidden lg:block text-sm  p-2 pt-0 pl-4 pr-4 rounded-xl'
        onClick={() => handleMenuItemClick('demo')}
      >
        <button
          className='whitespace-nowrap hover:rounded-md transition-all bg-white border-black border-1 px-3 py-2 font-medium text-black hover:shadow-xl  hover:bg-slate-700'
        >
          View Demo
        </button>
      </a>
      {isOpen && (
        <div className='lg:hidden absolute rounded-xl top-20 right-0 m-auto left-0 bg-white w-full text-black'>
          <div className='flex flex-col p-4 space-y-2'>
            <button
              onClick={() => handleMenuItemClick('home')}
              className='text-base hover:bg-blue-50  focus:outline-none p-2 pl-3 pr-3 rounded-md border-b border-gray-300'
            >
              Home
            </button>
            <button
              onClick={() => handleMenuItemClick('features')}
              className='text-base hover:bg-blue-50  focus:outline-none p-2 pl-3 pr-3 rounded-md border-b border-gray-300'
            >
              Features
            </button>
            <button
              onClick={() => handleMenuItemClick('testimonials')}
              className='text-base hover:bg-blue-50  focus:outline-none p-2 pl-3 pr-3 rounded-md border-b border-gray-300'
            >
              Testimonials
            </button>
            <button
              onClick={() => handleMenuItemClick('faqs')}
              className='text-base hover:bg-blue-50  focus:outline-none p-2 pl-3 pr-3 rounded-md border-b border-gray-300'
            >
              FAQs
            </button>
            <button
              className='whitespace-nowrap hover:rounded-md transition-all bg-white border-black border-1 px-3 py-2 font-medium text-black hover:shadow-xl mt-2  hover:bg-slate-700'
            >
              View Demo
            </button>
          </div>
        </div>
      )}
    </nav>
  );

};

export default NavBar;
