import React, {useState} from 'react';
import {Button, Modal} from 'antd';

const BatchEmailModal = ({batches, visible, onClose, onSendBatch}) => {
  const [processingBatch, setProcessingBatch] = useState(null);
  const [sentBatches, setSentBatches] = useState([]); // Track sent batches
  const totalEmails = batches.reduce((acc, batch) => acc + batch.length, 0);

  const handleSendBatch = async (batchIndex) => {
    setProcessingBatch(batchIndex);

    try {
      await onSendBatch(batches[batchIndex]); // Call the function to send the batch
      setSentBatches((prev) => [...prev, batchIndex]); // Mark batch as sent
      setProcessingBatch(null); // Reset after successful sending
    } catch (error) {
      console.error(`Error sending batch ${batchIndex}:`, error);
      setProcessingBatch(null); // Reset even on failure
    }
  };

  return (
    <Modal
      title={
        <h2 className="text-xl mulish-normal font-bold text-gray-800">
          Send Emails in Batches - Total {totalEmails} Emails
        </h2>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      width="90%"
      centered
      bodyStyle={{maxHeight: '70vh', overflowY: 'auto', padding: '1rem'}}
    >
      <div className="grid mt-2 gap-6">
        {batches.map((batch, index) => (
          <div
            key={index}
            className="rounded-lg px-3 mb-2 py-3 mulish-normal bg-white border "
          >
            <div className="flex mb-2 justify-between items-center">
              <h3 className="text-lg font-medium text-gray-700">
                Batch {index + 1}
              </h3>
              <Button
                type="primary"
                className={`mulish-normal text-white ${
                  sentBatches.includes(index)
                    ? 'cursor-not-allowed bg-black'
                    : 'bg-black hover:shadow-md'
                }`}
                loading={processingBatch === index}
                onClick={() => handleSendBatch(index)}
                disabled={
                  sentBatches.includes(index) ||
                  (processingBatch !== null && processingBatch !== index)
                }
              >
                {sentBatches.includes(index)
                  ? 'Batch Email Sent'
                  : processingBatch === index
                    ? 'Sending...'
                    : 'Send This Batch'}
              </Button>
            </div>
            <ul className="divide-y divide-gray-200">
              {batch.map((email, idx) => (
                <li
                  key={idx}
                  className="py-2 px-4 mb-2 flex justify-between items-center bg-gray-50 rounded-md"
                >
                  <div>
                    <p className="text-sm font-medium mb-1 text-gray-800">
                      <strong>To:</strong> {email.recipientEmail}
                    </p>
                    <p className="text-sm text-gray-600 truncate">
                      <strong>Subject:</strong> {email.subject}
                    </p>
                  </div>
                  <span className="text-sm text-gray-500">#{idx + 1}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default BatchEmailModal;
