// src/hooks/useAuth.js

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Cookies from 'js-cookie';
import {setAuthTokenAction} from '../redux/auth/authActions';
import {decodeToken} from '../utils/tokenUtils';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

const useAuthCheck = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    const checkAuth = async () => {
      const encodedToken = Cookies.get('cold-emails');
      if (encodedToken && authToken) {
        try {
          const decodedToken = decodeToken(encodedToken);
          dispatch(setAuthTokenAction(decodedToken));
          navigate('/template')
        } catch (error) {
          console.error('Token decoding failed:', error);
          Cookies.remove('cold-emails');
          toast.error('Session expired. Please log in.');
        }
      }
    };
    checkAuth();
  }, [dispatch, navigate]);
};

export default useAuthCheck;
